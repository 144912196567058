import React, { useState } from "react";
import { Container, Row } from "react-bootstrap";

import DstButton from "../../../components/DstButton/DstButton";
import DstFooter from "../../../components/DstFooter/DstFooter";
import DstNavbar from "../../../components/DstNavbar/DstNavbar";
import HeaderContent from "../../../components/HeaderContent/HeaderContent";
import HubspotForm from "../../../components/HubspotForm/HubspotForm";
import InfoCard from "../../../components/InfoCard/InfoCard";
import Popup from "../../../components/Popup/Popup";
import PreFooterOmnes from "../../../components/PreFooterOmnes/PreFooterOmnes";
import Title from "../../../components/DstTitle/DstTitle";
import DstModule from "../../../components/DstModule/DstModule";
import DstCertifications from "../../../components/DstCertifications/DstCertifications";

import { ContactSection } from "../Formation.component";
import { content } from "./TechnicienMaintenanceCvc.mock";

const certifications = [
  {
    content: "Préparation à l’habilitation électrique BR",
  },
  {
    content: "Attestation pour la manipulation des fluides frigorigènes",
  },
];

const TechnicienMaintenanceCvc = () => {
  const [showModal, setShowModal] = useState(false);

  return (
    <React.Fragment>
      <DstNavbar />
      <HeaderContent
        alt="automobile"
        image="/assets/images/home/automobile.png"
        title={
          <h1>
            Formez vos futurs collaborateurs au secteur de l’industrie en tant
            que <span className="blue-font">Technicien Maintenance CVC</span>
          </h1>
        }
        content={
          <div className="mt-3">
            <p>
              <strong>
                Grâce au partenariat entre Datascientest et l'École ORT, devenez
                un acteur clé du secteur du génie climatique et énergétique en
                intégrant une formation accélérée de 16 semaines pour devenir
                Technicien de Maintenance CVC.
              </strong>
            </p>
            <p>
              Gestion thermique, optimisation énergétique, systèmes connectés…
              rejoignez un domaine en pleine mutation technologique où chaque
              intervention contribue à relever les défis environnementaux
              d’aujourd’hui et de demain.
            </p>
            <p>
              Rejoignez les rangs des professionnels qualifiés et bénéficiez
              d’une formation alliant expertise technique et pratique sur le
              terrain. Pourquoi pas vous ?
            </p>
          </div>
        }
        dstButton={{
          value: "J’envoie mon cv",
          clickFunction: () => setShowModal(true),
        }}
      />
      <Container className="my-3 my-md-5">
        <div className="d-flex justify-content-end w-75">
          <Title text="Maintenance" underlineWidth={150} textUppercase />
        </div>
        <Title
          text="Modules clés"
          tag="h2"
          className="h3"
          color="#2424FF"
          underlineWidth={10}
          textUppercase
        />
        <Container className="py-3 py-md-5">
          {content.map((content, index) => (
            <DstModule
              key={index}
              title={content.title}
              module={content.objectives}
              duration={content.duration}
              className="my-5"
            />
          ))}
        </Container>

        <Container>
          <DstCertifications
            dstButton={{
              value: "Je suis intéressé.e",
              clickFunction: () => setShowModal(true),
              disableAnimation: true,
            }}
            certifications={certifications}
          />
        </Container>
      </Container>
      <div className="dark-background white-font py-3 py-md-5">
        <Container className="my-3 my-md-5">
          <Title
            text="Recrutement"
            className="h3"
            tag="h2"
            color="#20FFA4"
            underlineColor="#9D9FAB"
            textUppercase
          />
          <h3 className="h2 mb-3 mb-md-5">
            Comment s’organise le{" "}
            <span className="green-font">recrutement</span>
          </h3>
          <Row>
            <InfoCard
              title="Définition du besoin"
              text="DataScientest identifie les besoins spécifiques des entreprises et détermine les profils à sourcer, ainsi que la formation adaptée à ces profils."
              imageSrc="/assets/images/icon/write.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Sourcing"
              text="En accord avec les critères de sélection, DataScientest identifie
            et sélectionne les profils les plus proches des besoins
            spécifiques de l’entreprise. Ces profils bénéficieront d’une
            formation ciblée pour combler leurs lacunes."
              imageSrc="/assets/images/icon/light.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Formation"
              text="Une fois les profils sélectionnés et validés par l’entreprise, ils
            entament une formation intensive d’une durée maximale de 11
            semaines. À l’issue de cette formation, ils seront pleinement
            qualifiés et certifiés, prêts à répondre aux besoins de
            l’entreprise."
              imageSrc="/assets/images/icon/book.svg"
              borderColor={"#F7F7F7"}
            />
            <InfoCard
              title="Validation et embauche"
              text="La validation de la formation, et donc de l’embauche, est soumise
            à une soutenance à laquelle les équipes de l’entreprise sont
            invitées à assister. La décision finale d’embauche repose
            entièrement entre les mains de l’entreprise cliente."
              imageSrc="/assets/images/icon/work.svg"
              borderColor={"#F7F7F7"}
            />
          </Row>
          <div className="text-center">
            <DstButton
              value="télécharger la brochure"
              btnClass="m-bold"
              clickFunction={() => setShowModal(true)}
            />
          </div>
        </Container>
      </div>
      <Container className="mt-3 mt-md-5">
        <ContactSection
          portalId="19831339"
          formId="579c6f97-2c06-4ec0-ba1f-742922cc6853"
        />
      </Container>
      <PreFooterOmnes />
      <DstFooter />

      <Popup
        show={showModal}
        onClose={() => setShowModal(false)}
        modalTitle="Syllabus Technicien Maintenance Cvc"
      >
        <div>
          <HubspotForm
            portalId="19831339"
            formId="5705be1e-3baf-4d49-9f13-541b1db0c861"
          />
        </div>
      </Popup>
    </React.Fragment>
  );
};

export default TechnicienMaintenanceCvc;
