import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./DstCertifications.scss";
import DstTitle from "../DstTitle/DstTitle";
import DstButton from "../DstButton/DstButton";
import { DstButtonProps } from "../../models/Button";

interface Certifications {
  content: string;
}

interface DstModuleProps {
  certifications: Certifications[];
  title?: string;
  className?: string;
  value?: string;
  dstButton?: DstButtonProps;
}

const DstCertifications = ({
  certifications,
  title = "Certifications Complémentaires",
  className,
  dstButton,
}: DstModuleProps) => {
  return (
    <Container className={`blue-background p-5 ${className}`}>
      <Col className="d-flex align-items-center gap-4 pb-4">
        <img src="/assets/images/certif.png" alt="programme" />
        <DstTitle
          text={title}
          underlineEnabled={false}
          tag="h2"
          className="fw-normal m-0"
          color="white"
        />
      </Col>

      {certifications.map((certification, index) => (
        <Row key={`module-${index}`} className="align-items-start p-1 mb-2">
          <div className="border-left p-2 ml-2 text-uppercase light-font m-0 p-0 border-layout text-align-center">
            {certification.content}
          </div>
        </Row>
      ))}

      {dstButton && (
        <Col>
          <div className="mb-2 mt-3 mb-md-5">
            <DstButton {...dstButton} />
          </div>
        </Col>
      )}
    </Container>
  );
};

export default DstCertifications;
