import { Container, Col, Row } from "react-bootstrap";
import "./DstModule.scss";
import DstTitle from "../DstTitle/DstTitle";

interface Module {
  description: string;
}

const DstModule = ({
  module,
  title,
  duration,
  className,
}: {
  module: Module[];
  title: string;
  duration: string;
  className?: string;
}) => {
  return (
    <Container className={`bg-light p-5 ${className}`}>
      <Col className="d-sm-flex align-items-center gap-4 pb-4">
        <DstTitle
          text={title}
          textUppercase
          underlineEnabled={false}
          tag="h2"
          className="fw-normal m-0"
        />
        <p className="p-sm-3 p-2 blue-font border-blue fw-normal text-uppercase mt-sm-0 m-0 mt-2">
          Volume Horaire : {duration}
        </p>
      </Col>
      {module.map((module, index) => (
        <Row key={`module-${index}`} className="align-items-start p-1 mb-2">
          <Col
            xs="auto"
            className="green-background"
            style={{ minWidth: "5%" }}
          >
            <h3 className="p-2 m-0 text-uppercase">{index + 1}.</h3>
          </Col>
          <Col>
            <p className="border-bottom pb-3 text-uppercase">
              {module.description}
            </p>
          </Col>
        </Row>
      ))}
    </Container>
  );
};

export default DstModule;
