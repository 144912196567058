export const content = [
  {
    title: "Module chauffage",
    duration: "180 heures",
    objectives: [
      {
        description:
          "Exécuter la maintenance préventive des équipements thermiques et des réseaux de distribution de chauffage et d’eau chaude sanitaire.",
      },
      {
        description:
          "Réaliser le diagnostic, le dépannage ou la réparation des équipements thermiques et des réseaux de distribution de chauffage et d’eau chaude sanitaire.",
      },
      {
        description:
          "Optimiser les réglages des équipements thermiques et des réseaux de distribution de chauffage et d’eau chaude sanitaire.",
      },
    ],
  },
  {
    title: "Module froid",
    duration: "136 heures",
    objectives: [
      {
        description:
          "Exécuter la maintenance préventive des équipements thermodynamiques et des réseaux de distribution d’eau glacée.",
      },
      {
        description:
          "Réaliser le diagnostic, le dépannage ou la réparation des équipements thermodynamiques et des réseaux de distribution d’eau glacée.",
      },
      {
        description:
          "Optimiser les réglages des équipements thermodynamiques et des réseaux de distribution d’eau glacée.",
      },
    ],
  },
  {
    title: "Module climatisation",
    duration: "56 heures",
    objectives: [
      {
        description:
          "Exécuter la maintenance préventive des équipements de traitement d’air.",
      },
      {
        description:
          "Réaliser le diagnostic, le dépannage ou la réparation des équipements de traitement d’air.",
      },
      {
        description:
          "Optimiser les réglages des équipements de traitement d’air.",
      },
    ],
  },
];
