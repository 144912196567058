import { BrowserRouter, Route, Routes } from "react-router-dom";

import Home from "./pages/Home/Home";
import Lighting from "./pages/Formations/Automobile/Lighting";
import NotFound from "./pages/NotFound/NotFound";
import SystemEngineer from "./pages/Formations/Automobile/SystemEngineer";
import SoftwareDeveloper from "./pages/Formations/Automobile/SoftwareDeveloper";
import AccueilHandicap from "./pages/AccueilHandicap/AccueilHandicap";
import Rdv from "./pages/Rdv/Rdv";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Ecole from "./pages/Ecole/Ecole";
import Financement from "./pages/Financement/Financement";
import MentionsLegales from "./pages/MentionsLégales/MentionsLégales";
import LiaisonSol from "./pages/Formations/Automobile/LiaisonSol";

import "./App.scss";
import IngenieurSureteNucleaire from "./pages/Formations/Nucleaire/IngenieurSureteNucleaire";
import IngenieurFonctionnementNucleaire from "./pages/Formations/Nucleaire/IngenieurFonctionnementNucleaire";
import IngenieurQualiteNucleaire from "./pages/Formations/Nucleaire/IngenieurQualiteNucleaire";
import IngenieurSystemeFerroviaire from "./pages/Formations/Ferroviaire/IngenieurSystemeFerroviaire";
import ConcepteurSystemesEmbarques from "./pages/Formations/Aeronautique/ConcepteurSystemesEmbarques";
import TechnicienBancEssai from "./pages/Formations/Aeronautique/TechnicienBancEssai";
import DessinateurConstructionNavale from "./pages/Formations/Navale/DessinateurConstructionNavale";
import DessinateurProjeteurTuyauterie from "./pages/Formations/Navale/DessinateurProjeteurTuyauterie";
import Formations from "./pages/Formations/Formations";
import FormationsAutomobile from "./pages/Formations/Automobile/FormationsAutomobile";
import FormationsAeronautique from "./pages/Formations/Aeronautique/FormationsAeronautique";
import FormationsFerroviaire from "./pages/Formations/Ferroviaire/FormationsFerroviaire";
import FormationsNavale from "./pages/Formations/Navale/FormationsNavale";
import FormationsNucleaire from "./pages/Formations/Nucleaire/FormationsNucleaire";
import FormationsTechnicienMaintenanceCVC from "./pages/Formations/Aeronautique/TechnicienMaintenanceCvc";

const App = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/*
          Add routes here by using example on:
          <Route path="{YOUR_PATH}" element={YOUR_COMPONENT} />
        */}
        <Route path="/" element={<Home />} />
        <Route path="/rdv" element={<Rdv />} />
        {/* automobile */}
        <Route path="/nos-formations" element={<Formations />} />
        <Route
          path="/formations-automobile"
          element={<FormationsAutomobile />}
        />
        <Route path="/formation-system-engineer" element={<SystemEngineer />} />
        <Route path="/formation-lighting" element={<Lighting />} />
        <Route path="/formation-liaison-sol" element={<LiaisonSol />} />
        <Route
          path="/formation-software-developer"
          element={<SoftwareDeveloper />}
        />
        {/* nucléaire */}
        <Route path="/formations-nucleaire" element={<FormationsNucleaire />} />
        <Route
          path="/formation-ingenieur-surete-nucleaire"
          element={<IngenieurSureteNucleaire />}
        />
        <Route
          path="/formation-ingenieur-fonctionnement-nucleaire"
          element={<IngenieurFonctionnementNucleaire />}
        />
        <Route
          path="/formation-ingenieur-qualite-nucleaire"
          element={<IngenieurQualiteNucleaire />}
        />
        {/* ferroviaire */}
        <Route
          path="/formations-ferroviaire"
          element={<FormationsFerroviaire />}
        />
        <Route
          path="/formation-ingenieur-systeme-ferroviaire"
          element={<IngenieurSystemeFerroviaire />}
        />
        {/* aéronautique */}
        <Route
          path="/formations-aeronautique"
          element={<FormationsAeronautique />}
        />
        <Route
          path="/formation-concepteur-systemes-embarques"
          element={<ConcepteurSystemesEmbarques />}
        />
        <Route
          path="/formation-technicien-banc-essai"
          element={<TechnicienBancEssai />}
        />
        {/* navale */}
        <Route path="/formations-navale" element={<FormationsNavale />} />
        <Route
          path="/formation-dessinateur-construction-navale"
          element={<DessinateurConstructionNavale />}
        />
        <Route
          path="/formation-dessinateur-projeteur-tuyauterie"
          element={<DessinateurProjeteurTuyauterie />}
        />
        {/* other */}
        <Route
          path="/formation-technicien-maintenance-cvc"
          element={<FormationsTechnicienMaintenanceCVC />}
        />
        <Route path="/notre-ecole" element={<Ecole />} />
        <Route path="/accueil-handicap" element={<AccueilHandicap />} />
        <Route path="/financement" element={<Financement />} />
        <Route path="/mentions-legales" element={<MentionsLegales />} />
        <Route path="/*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
